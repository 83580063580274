import TYPES from '@/types';
import Vue from 'vue';

// Application
import GetEmploymentSituationQuery
  from '@/modules/flagship/catalogs/employment-situation/application/queries/get-employment-situation-query';
import UpdateInvestorProfileOnEmploymentSituationCommand
  from '@/modules/flagship/investor-profile/employment-situation/application/commands/update-investor-profile-on-employment-situation-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import {
  EmploymentSituationEntity,
} from '@/modules/flagship/catalogs/employment-situation/domain/entities/employment-situation-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipProfilingStepEmploymentSituationViewModel {
  @Inject(TYPES.GET_EMPLOYMENT_SITUATION_QUERY)
  private readonly get_employment_situation_query!: GetEmploymentSituationQuery;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_ON_EMPLOYMENT_SITUATION_COMMAND)
  // eslint-disable-next-line max-len
  private readonly update_employment_situation_command!: UpdateInvestorProfileOnEmploymentSituationCommand;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship_profiling_step_employment_situation';

  employment_situation: EmploymentSituationEntity[] = [];

  employment_situation_selected = '';

  employment_related_to_finances = null;

  employment_related_to_finances_options: Array<any> = [];

  private investor_profile_entity: InvestorProfileEntity = {
    id: '',
    customer_id: sessionStorage.getItem('user_id')!,
    custom_goals: {},
    is_showed: false,
    person_id: '',
    employment_situation_id: '',
    recommended_investment_product_id: '',
    emergency_fund_availability_id: '',
    has_emergency_fund: true,
    net_monthly_expenses: 0,
    elder_dependent_count: 0,
    is_completed: true,
    child_dependent_count: 0,
    pet_dependent_count: 0,
    net_monthly_income: 0,
    active_goals_count: 0,
    goals_completed: false,
    emergency_fund_locations: {},
    retirement_fund_locations: {},
    exit_pool: {},
    finance_related_job: false,
    income_behavior_in_following_year_id: '',
    emergency_fund_amount: 0,
    has_retirement_fund: null,
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  get is_disabled() {
    return !this.employment_situation_selected
    || this.employment_related_to_finances === null;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  loadEmploymentRelatedToFinancesOptions = () => {
    this.employment_related_to_finances_options.push({
      label: this.translate('yes'),
      value: true,
    });
    this.employment_related_to_finances_options.push({
      label: this.translate('no'),
      value: false,
    });
  }

  loadInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute(false);
      this.investor_profile_entity.id = investor_profile.id;
      this.employment_situation_selected = investor_profile.employment_situation_id;
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.employment_related_to_finances = investor_profile.finance_related_job;
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_investor_profile'),
      );
    }
  }

  loadEmploymentSituations = async () => {
    try {
      this.employment_situation = await this.get_employment_situation_query.execute();
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_employment_situations'),
      );
    }
  }

  updateInformation = async () => {
    try {
      this.view.$emit('loadingStep', true);
      this.investor_profile_entity.employment_situation_id = this.employment_situation_selected;
      this.investor_profile_entity.finance_related_job = (
        this.employment_related_to_finances || false
      );
      await this.update_employment_situation_command.execute(this.investor_profile_entity);

      this.view.$emit('nextStep');

      return true;
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.update_profile'),
      );
      return false;
    } finally {
      this.view.$emit('loadingStep', false);
    }
  }

  initialize = async () => {
    this.view.$emit('loadingStep', true);
    this.loadEmploymentRelatedToFinancesOptions();
    await this.loadEmploymentSituations();
    await this.loadInvestorProfile();
    this.view.$emit('loadingStep', false);
  }
}
